.c-tag {
  display: inline-block;
  background-color: $color_white;
  color: $color_primary;
  border: solid 1px $color_primary;
  padding: 0.4em;
  border-radius: 5px;
  text-decoration: none;
  margin: 2px;

  &--green {
    border-color: $color_green;
    color: $color_green;
  }

  &--blue {
    border-color: $color_blue;
    color: $color_blue;
  }

  &--yellow {
    border-color: $color_yellow;
    color: $color_yellow;
  }

  &--orange {
    border-color: $color_orange;
    color: $color_orange;
  }

  &--black {
    border-color: $color_black;
    color: $color_black;
  }

  &--cenere {
    border-color: $color_cenere;
    color: $color_black;
  }

  &--bold {
    font-weight: bold;
  }
}
