.c-footer {
  width: calc(100% - #{$size_sidebar_width});
  height: 50px;
  margin-left: $size_sidebar_width;
  padding: 0 15px;
  transition: all 0.3s;

  &--full {
    width: 100%;
    margin-left: 0;
  }
}

.c-footer__content {
  padding: 15px 0;
  font-size: 12px;
  color: $color_black;
  border-top: solid 1px darken($color_cenere, 5%);

  a {
    color: $color_black;
  }
}

.is-menu-closed {
  .c-footer {
    width: 100%;
    margin-left: 0;
  }
}

.is-primary {
  .c-footer__content {
    color: $color_white;

    a {
      color: $color_white;
    }
  }
}

@media (max-width: $breakpoint_sm) {
  .c-footer {
    width: 100%;
    margin-left: 0;

    &--full {
      width: 100%;
      margin-left: 0;
    }
  }
}