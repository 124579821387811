.c-message {
  padding: 30px 15px;

  &--bordered {
    border: solid 1px $color_cenere;
  }
}

.c-message__title {
  display: block;
  text-align: center;
  color: $color_black;
  font-size: 30px;
  margin-bottom: 15px;
}

.c-message__content {
  display: block;
  text-align: center;
  color: $color_black;
  font-size: 20px;

  &--small {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
