@import 'variables';
@import '../../../vendor/matilda_core_front/style/imports';

// CODICE DI INPUT ISTANZA.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.c-inputinstance {
  display: flex;
  flex-direction: row;
  align-items: center;

  .c-input {
    width: 100%;
  }
}


// COMPONENTE DI VISUALIZZATORE YOUTUBE
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.c-youtube {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;

  iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

// COMPONENTE DI VISUALIZZATORE PDF
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.c-pdf {
  object, iframe {
    width: 100%;
    height: 600px;
  }
}

// COMPONENTE INPUT FILES
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.c-input-files {

}

.c-input-files__label {

}

.c-input-files__list {
  display: flex;
  flex-direction: row;
}

.c-input-files__item {
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  margin-right: 5px;
  border: solid 1px darken($color_cenere, 10%);
  position: relative;
  overflow: hidden;

  &--hover {
    .c-input-files__item-action {
      opacity: 0;
      transition: all 0.35s ease;
    }

    &:hover {
      .c-input-files__item-action {
        opacity: 1;
      }
    }
  }
}

.c-input-files__item-action {
  position: absolute;
  background-color: rgba($color_primary, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $color_white;
  cursor: pointer;
  text-align: center;

  i {
    font-size: 20px;
  }

  span {
    display: block;
    font-size: 10px;
  }
}

.c-input-files__item-action-top {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

// COMPONENTE LOADING
//////////////////////////////////////////////////////////////////////////////////////////////////////////

.c-loading > div {
  position: absolute;
  top: 50%; left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 15px;
}

.c-loading {
  min-height: 200px;
  width: 100%;

  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}