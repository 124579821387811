.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  &.is-active {
    visibility: visible;
    opacity: 1;

    .c-modal__box {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.c-modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color_black, 0.6);
}

.c-modal__box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  transition: all 0.3s ease-out 0.1s;
  opacity: 0;
  transform: translate(-50%, 50%);

  &--large {
    width: 900px;
  }

  > .c-card {
    .c-card__body {
      max-height: 70vh;
      overflow-y: auto;
    }
  }
}

@media (max-width: $breakpoint_md) {
  .c-modal__box {
    &--large {
      width: calc(100% - 30px);
    }
  }
}

@media (max-width: $breakpoint_sm) {
  .c-modal {
    opacity: 1;

    &.is-active {
      .c-modal__box {
        transform: translate(0);
      }
    }
  }

  .c-modal__overlay {
    display: none;
  }

  .c-modal__box {
    width: 100%;
    top: $size_header_height;
    left: 0;
    transform: translate(0, 100%);
    padding: 0;
    opacity: 1;

    > .c-card {
      height: calc(100vh - #{$size_header_height});
      overflow-y: auto;

      .c-card__body {
        max-height: initial;
        overflow-y: hidden;
      }
    }
  }
}