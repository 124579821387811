.c-tile {
  display: block;
  background-color: $color_white;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  transition: box-shadow 0.25s linear;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  color: $color_black;

  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.05);
    color: $color_primary;
  }
}

.c-tile__label {
  font-size: 18px;
  font-weight: bold;
}

.c-tile__icon {
  font-size: 45px;
  margin-bottom: 15px;
}

@media (max-width: $breakpoint_sm) {
  .c-tile {
    margin: 0 15px;
  }
}